#loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

@keyframes ldio-ucwibazh2i9 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#loader .loadingio-spinner-rolling-3kjtqyynjid .ldio-ucwibazh2i9 div {
  position: absolute;
  width: 18.88px;
  height: 18.88px;
  border: 7.36px solid #002147;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ldio-ucwibazh2i9 0.7575757575757576s linear infinite;
  top: 52px;
  left: 92px;
  box-sizing: content-box;
}

#loader .loadingio-spinner-rolling-3kjtqyynjid {
  width: 184px;
  height: 184px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

#loader .loadingio-spinner-rolling-3kjtqyynjid .ldio-ucwibazh2i9 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

@media only screen and (max-width: 600px) {
  .ant-menu-submenu-popup {
    top: 65px !important;
    width: 100vw;
  }

  .container {
    padding: 0 20px;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .ant-menu-submenu-popup {
    top: 65px !important;
  }

  .container {
    padding: 0 150px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  img {
    max-width: 100%;
    object-fit: contain;
    object-position: top;
    vertical-align: middle;
  }
}


.ant-menu-sub>.ant-menu-item-only-child {
  border-top: 1px solid #fdc800 !important;
  height: auto !important;
  margin: 0 0 0 0 !important;
}

.ant-menu-item-only-child:hover {
  background: transparent !important;
}

.ant-menu-sub>.ant-menu-item-only-child:hover {
  background: #1A3B61 !important;
}

.ant-menu-submenu-title:hover {
  color: #fdc800 !important;
}

.ant-menu-submenu-title:active {
  color: #fff !important;
}

.ant-notification-notice-message {
  font-size: 14px !important;
}

.ant-menu-sub {
  background: #002147 !important;
}

.ant-menu-submenu-selected {
  background: transparent !important;
  color: #fdc800 !important
}

.ant-menu-item-selected {
  background: transparent !important;
  color: #fdc800 !important
}

.ant-menu-item:hover {
  color: #fdc800 !important;
}

.ant-spin-nested-loading {
  height: 100% !important;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: unset !important;
}

.ant-layout {
  background: #fff !important;
}

.ant-fixed-header {
  position: fixed;
  z-index: 999;
  width: 100%;
  background: rgba(0, 33, 71, 0.9) !important;
  transition: all 1s ease-out;
  opacity: 0.8;
}

.ant-layout-footer {
  padding: 70px 0 70px 0 !important;
}

.ant-card-meta-description>div>p>img {
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.ant-card:hover {
  box-shadow: 0 0 8px 1px rgb(0 0 0 / 20%);
  transition: box-shadow .6s ease-in;
  border-radius: 10px;
}

.ant-card:hover>.ant-card-cover>div {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  transition: all 1s ease-in-out;
}

.ant-card:hover>.ant-card-actions {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ant-breadcrumb-separator {
  color: #fdc800 !important;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.m-auto {
  margin: auto !important;
}

.pt-10 {
  padding-top: 15px !important;
}

.pb-10 {
  padding-bottom: 15px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.color-primary {
  color: #002147 !important;
}

.color-white {
  color: #fff !important;
}

.color-mute {
  color: #999999 !important;
}

.color-warning {
  color: #fdc800 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bg-primary {
  background: #002147 !important;
}

.bg-secondary {
  background: rgba(0, 33, 71, 0.9) !important;
}

.bg-dark {
  background: #001a39 !important;
}

.bg-white {
  background: #fff !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.header-label-title {
  position: relative;
}

.header-label-title h3 {
  z-index: 2;
  position: absolute;
  top: -32px;
}

.header-label-title:after {
  content: "";
  width: 100%;
  height: 8px;
  background: #fdc800;
  position: absolute;
  left: 0;
  bottom: 5px;
  z-index: 1;
}

.header-label-title a {
  position: absolute;
  right: 0px;
  bottom: -15px;
  z-index: 2;
}

.header-label-title a:hover {
  right: -10px;
}

.header-label-title a svg {
  vertical-align: middle;
}

.word-break {
  word-wrap: break-word;
}

.align-start {
  align-items: flex-start !important;
}